import React from 'react';
import { styleConstants } from '../../style-constants';
import { Heading } from '../../components/Heading';
import { Paragraph } from '../../components/Paragraph';
import { Enumeration } from '../../components/Enumeration';

interface IOffsettingSectionProps {

}

export const OffsettingSection: React.FC<IOffsettingSectionProps> = (props) => {

    const renderOffsettingSection = () => {
        return (
            <div className='flex w-full flex-col items-center'>
                <Heading
                    text={`Solana 2024 Carbon Offsets`}
                    isSubheading
                    hasMediumSmallBottomPadding
                    isMdTextCenter
                />
                <div
                    className={`${styleConstants.boxStyle} !${styleConstants.ccriBoxBackgroundColor} flex flex-col space-y-3 justify-center items-center`}
                >
                    <Paragraph
                        content="The Solana Foundation is committed to ensuring the Solana network is carbon neutral. As part of this commitment, the Foundation purchases offsets equal to or greater than the network's carbon footprint for the previous year."
                        isWhiteText
                        isCenter
                    />
                    <Paragraph
                        content={"In 2023, the Solana network's carbon footprint was roughly 12,899 tCO\u2082. This was offset by purchases of two blockchain-native sources of carbon credits."}
                        isWhiteText
                        isCenter
                    />
                    <Paragraph
                        content="Below are details on the Solana Foundation's purchases for the network's carbon footprint in 2023:"
                        isWhiteText
                        isCenter
                    />
                    <Enumeration
                        listContent={[
                            {
                                text: ["", " retired of NCT via "],
                                links: ["https://solscan.io/account/Ghse8SzayaYdEzRQRe91eMkTU9oNzbbqLiwBs7Y3saXK#portfolio", "https://www.sunrisestake.com"],
                                linkTexts: ["45,199.35 tCO\u2082", "Sunrise Stake"]
                            },
                            {
                                text: ["", " retired via "],
                                links: ["https://scan.ecotoken.earth", "https://ecotoken.earth"],
                                linkTexts: ["7449.6 tCO\u2082", "ecoToken"]
                            },
                        ]}
                        isWhiteText
                    />
                </div>
            </div>

        );
    };


    return (
        renderOffsettingSection()
    )
};
