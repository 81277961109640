import { useNavigate } from "react-router-dom";
import SolanaLogo from "../../assets/logos/solana-logo.png"
import { TLinks } from "../../types/component-types";
import { styleConstants } from "../../style-constants";
import { Paragraph } from "../../components/Paragraph";


interface IFooterProps {
  navbarLinks: TLinks,
}


export const Footer: React.FC<IFooterProps> = (props) => {

  const navigate = useNavigate();


  const handleLogoClick = () => {
    navigate(props.navbarLinks["home"].link)
  }


  const iconInfos: {
    name: string,
    link: string;
    icon: JSX.Element;
  }[] = [
      {
        name: "Youtube",
        link: "https://www.youtube.com/SolanaFndn",
        icon: <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M23.3 7.3c0-.2-.3-1.8-1-2.5-.9-1-1.9-1.1-2.4-1.1h-.1c-3.1-.2-7.7-.2-7.8-.2 0 0-4.7 0-7.8.2h-.1c-.5 0-1.5.1-2.4 1.1-.7.8-1 2.4-1 2.6 0 .1-.2 1.9-.2 3.8v1.7c0 1.9.2 3.7.2 3.8 0 .2.3 1.8 1 2.5.8.9 1.8 1 2.4 1.1h.3c1.8.2 7.3.2 7.5.2 0 0 4.7 0 7.8-.2h.1c.5-.1 1.5-.2 2.4-1.1.7-.8 1-2.4 1-2.6 0-.1.2-1.9.2-3.8v-1.7c.1-1.8-.1-3.7-.1-3.8zm-7.4 4.9-6 3.2c-.1 0-.1.1-.2.1s-.2 0-.2-.1c-.1-.1-.2-.2-.2-.4V8.5c0-.2.1-.3.2-.4s.3-.1.5 0l6 3.2c.2.1.3.2.3.4s-.2.4-.4.5z"
            fill="currentcolor"
          >
          </path>
        </svg>
      },
      {
        name: "X",
        link: "https://x.com/solana",
        icon: <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865l8.875 11.633Z"
            fill="currentcolor"
          >
          </path>
        </svg>
      },
      {
        name: "Discord",
        link: "https://solana.com/discord",
        icon: <svg width="16" height="16" viewBox="0 0 33.867 33.867" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M11.343 5.177c-1.076 0-4.32 1.316-4.902 1.579-.582.263-1.228 1.084-1.961 2.439-.734 1.355-1.323 2.939-2.28 5.269-.956 2.33-1.179 6.822-1.147 8.193.032 1.371.189 2.442 1.594 3.253 1.404.81 2.646 1.658 3.953 2.168 1.308.51 2.2.877 2.806.367.606-.51 1.005-1.403 1.005-1.403s.574-.797-.51-1.275c-1.084-.479-1.626-.814-1.579-1.308.048-.494.127-.765.398-.701.271.064.91 1.211 3.365 1.737s4.848.447 4.848.447 2.394.08 4.849-.447c2.455-.526 3.093-1.673 3.364-1.737.271-.064.35.207.398.7.048.495-.494.83-1.578 1.309-1.084.478-.51 1.275-.51 1.275s.399.892 1.005 1.403c.605.51 1.498.143 2.805-.367 1.307-.51 2.55-1.357 3.954-2.168 1.405-.811 1.562-1.882 1.594-3.253.032-1.37-.191-5.863-1.148-8.193-.956-2.33-1.546-3.914-2.28-5.269-.732-1.355-1.379-2.176-1.96-2.44-.582-.262-3.827-1.578-4.903-1.578-1.076 0-1.394.75-1.394.75l-.375.829s-2.52-.479-3.804-.48c-1.284 0-3.837.48-3.837.48l-.375-.83s-.318-.749-1.395-.749zm.117 9.948h.04c1.569 0 2.84 1.373 2.84 3.066 0 1.694-1.271 3.066-2.84 3.066s-2.84-1.372-2.84-3.066c-.001-1.677 1.247-3.043 2.8-3.066zm10.907 0h.04c1.553.023 2.8 1.39 2.8 3.066 0 1.694-1.271 3.066-2.84 3.066-1.57 0-2.84-1.372-2.84-3.066 0-1.693 1.27-3.066 2.84-3.066z"
            fill="currentcolor"
          >
          </path>
        </svg>
      },
      {
        name: "Reddit",
        link: "https://solana.com/reddit",
        icon: <svg width="16" height="16" viewBox="0 0 56.7 56.7" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M53.048 28.446a5.696 5.696 0 0 0-5.69-5.69 5.647 5.647 0 0 0-3.75 1.419c-3.763-2.408-8.784-3.925-14.322-4.089l3.389-11.59 8.32 1.546c0 .068-.014.132-.01.2a3.6 3.6 0 0 0 3.774 3.408 3.6 3.6 0 0 0 3.407-3.774 3.6 3.6 0 0 0-3.774-3.408 3.587 3.587 0 0 0-2.945 1.84l-.003-.001-10.026-1.863-3.993 13.64c-5.552.147-10.589 1.656-14.368 4.059a5.647 5.647 0 0 0-3.715-1.387 5.697 5.697 0 0 0-5.69 5.69c0 1.94.999 3.73 2.617 4.774a10.124 10.124 0 0 0-.155 1.73c0 8.212 9.956 14.894 22.194 14.894S50.5 43.162 50.5 34.949c0-.568-.052-1.128-.145-1.68a5.69 5.69 0 0 0 2.692-4.823zM17.76 32.318a3.496 3.496 0 1 1 6.993.002 3.496 3.496 0 0 1-6.993-.002zm18.79 9.458c-.104.108-2.613 2.661-8.283 2.661-5.7 0-7.98-2.588-8.073-2.698a.894.894 0 0 1 1.352-1.168c.052.056 1.954 2.079 6.72 2.079 4.85 0 6.976-2.095 6.998-2.115a.895.895 0 0 1 1.286 1.24zm-.695-5.961a3.496 3.496 0 1 1-.003-6.992 3.496 3.496 0 0 1 .003 6.992z"
            fill="currentcolor"
          >
          </path>
        </svg>
      },
      {
        name: "GitHub",
        link: "https://solana.com/github",
        icon: <svg width="16" height="16" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M24 0C10.747 0 0 11.017 0 24.607c0 10.873 6.877 20.097 16.413 23.35 1.2.228 1.64-.533 1.64-1.183 0-.587-.023-2.525-.033-4.581-6.677 1.488-8.086-2.904-8.086-2.904-1.092-2.844-2.665-3.6-2.665-3.6-2.177-1.528.165-1.496.165-1.496 2.41.173 3.679 2.535 3.679 2.535 2.14 3.762 5.614 2.675 6.984 2.046.215-1.59.837-2.676 1.523-3.29-5.33-.623-10.934-2.733-10.934-12.162 0-2.686.937-4.882 2.473-6.605-.25-.62-1.071-3.123.232-6.512 0 0 2.016-.661 6.602 2.522 1.914-.545 3.968-.818 6.007-.828 2.04.01 4.095.283 6.013.828 4.58-3.183 6.593-2.522 6.593-2.522 1.306 3.39.484 5.892.235 6.512 1.54 1.723 2.47 3.919 2.47 6.605 0 9.452-5.614 11.533-10.959 12.142.861.764 1.628 2.261 1.628 4.557 0 3.292-.027 5.942-.027 6.753 0 .655.432 1.422 1.648 1.18C41.132 44.697 48 35.476 48 24.607 48 11.017 37.255 0 24 0Z" fill="currentcolor"
          >
          </path>
          <path
            d="M9.09 35.331c-.053.123-.24.159-.411.075-.174-.08-.272-.247-.216-.37.052-.126.24-.16.414-.077.174.081.273.25.213.372ZM10.062 36.443c-.114.108-.338.058-.49-.114-.157-.172-.186-.401-.07-.511.118-.11.335-.058.492.113.157.174.188.402.068.512ZM11.008 37.86c-.146.105-.387.007-.536-.212-.147-.22-.147-.482.004-.587.149-.105.385-.01.536.207.147.222.147.485-.004.592ZM12.305 39.23c-.132.148-.412.108-.617-.095-.21-.198-.268-.48-.136-.629.133-.149.415-.107.621.094.209.198.272.482.132.63ZM14.093 40.024c-.058.193-.328.28-.6.199-.27-.085-.448-.31-.394-.505.057-.194.328-.286.602-.198.27.084.449.308.392.504ZM16.058 40.172c.006.203-.224.37-.51.374-.287.007-.519-.157-.522-.357 0-.205.226-.371.512-.376.286-.006.52.157.52.359ZM17.885 39.853c.034.198-.164.401-.447.455-.279.052-.537-.07-.572-.266-.035-.203.167-.407.445-.46.284-.05.538.07.574.27Z" fill="currentcolor"
          >
          </path>
        </svg>
      },
      {
        name: "Telegram",
        link: "https://solana.com/telegram",
        icon: <svg width="16" height="16" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="m484.689 98.231-69.417 327.37c-5.237 23.105-18.895 28.854-38.304 17.972L271.2 365.631l-51.034 49.086c-5.647 5.647-10.372 10.372-21.256 10.372l7.598-107.722L402.539 140.23c8.523-7.598-1.848-11.809-13.247-4.21L146.95 288.614 42.619 255.96c-22.694-7.086-23.104-22.695 4.723-33.579L455.423 65.166c18.893-7.085 35.427 4.209 29.266 33.065z"
            fill="currentcolor"
          >
          </path>
        </svg>
      }
    ]

  return (
    <footer className="py-4 px-6 mb-2">
      <div className="container mx-auto px-0 xs:px-2 sm:px-0 flex flex-col space-y-4 xs:space-y-0 xs:flex-row items-center justify-between">
        {/* Left Side - Logo */}
        <div className="flex items-center space-x-0 md:space-x-4 space-y-4 md:space-y-0 flex-col md:flex-row">
          <img
            src={SolanaLogo}
            alt="Solana"
            className="h-6 w-auto cursor-pointer"
            onClick={handleLogoClick} />
          <div>
            <Paragraph
              content={
                {
                  text: ["", ' | '],
                  links: ["https://solana.com", "https://solana.com/tos"],
                  linkTexts: ["Solana.com", "Terms of Service"],
                }
              }
              isLinkNotBold
              isThinText
              customStyle="!text-xs md:!text-sm !text-white"
            />
          </div>

        </div>

        {/* Center - Copyright & Terms */}
        <div className="sm:justify-self-start">

        </div>

        {/* Right Side - Social Media Icons */}
        <div className="flex flex-wrap justify-evenly sm:justify-normal sm:flex-nowrap max-w-[200px] xxs:max-w-none sm:space-x-8 space-x-4">
          {iconInfos.map((elem) => {
            return (
              <a
                href={elem.link}
                key={`link-${elem.name}`}
                className={`text-black hover:text-white w-[25px] h-[25px] bg-[#848895] flex justify-center items-center rounded-full ${styleConstants.transitionStandard}`}>
                {elem.icon}
              </a>
            )
          })}
        </div>
      </div>
    </footer>
  )
}