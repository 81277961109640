import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../state/hooks";
import { isAllDashboardDataLoaded, loadUnavailableDashboardData } from "../../state/reducers/dashboardReducerHelpers";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { TBackendHistoric, TBackendKpi, TBackendMapData, TBackendTickers, TBackendNetworkTicker } from "../../types/backend-data";
import { KpiSection } from "../../sections/KpiSection";
import { MapSection } from "../../sections/MapSection";
import { HistoricSection } from "../../sections/HistoricSection";
import { setSelectedNetwork } from "../../state/actions/dashboardActions";
import { ComparisonSection } from "../../sections/ComparisonSection";
import { BackendOfflineAlert } from "../../components/BackendOfflineAlert";
import { PageTransitionWrapper } from "../../components/PageTransitionWrapper";
import { isMapDataAvailable } from "./mainDashboardHelpers";
import { Transition } from "@headlessui/react";
import { styleConstants } from "../../style-constants";
import { HeroSection } from "../../sections/HeroSection";
import { PoweredByCcri } from "../../components/PoweredByCcri";
import { OffsettingSection } from "../../sections/OffsettingSection";


interface IMainDashboardProps {

}


export const MainDashboard: React.FC<IMainDashboardProps> = (props) => {

  const dispatch = useAppDispatch()

  const [dashboardLoading, setDashboardLoading] = useState<boolean>(true);

  const tickers = useAppSelector<TBackendTickers>(state => state.dashboard.tickers);
  const kpiData = useAppSelector<TBackendKpi>(state => state.dashboard.kpiData);
  const mapData = useAppSelector<TBackendMapData>(state => state.dashboard.mapData);
  const historicData = useAppSelector<TBackendHistoric>(state => state.dashboard.historicData);
  const selectedNetwork = useAppSelector<TBackendNetworkTicker>(state => state.dashboard.selectedNetwork)
  const isBackendOffline = useAppSelector<boolean>(state => state.session.isBackendOffline)


  useEffect(() => {

    //load data if not yet loaded, on initial page load
    if (false === isAllDashboardDataLoaded()) {
      setDashboardLoading(true)
      loadUnavailableDashboardData(dispatch)
    }

  }, [dispatch]);


  useEffect(() => {

    //check if all data there on each update, set loading to false if all are there
    if (true === isAllDashboardDataLoaded()) {
      if (undefined === selectedNetwork) {
        // on initial load, set selectedNetworkName to the first network
        dispatch(setSelectedNetwork(tickers[0]))
      }
      setDashboardLoading(false)
    } else if (true === isBackendOffline) {
      setDashboardLoading(false)
    }
  }, [dispatch, tickers, kpiData, mapData, historicData, isBackendOffline, selectedNetwork]);


  const renderComponent = () => {
    if (dashboardLoading) {
      return <LoadingSpinner />
    } else if (false === dashboardLoading && true === isBackendOffline) {
      return <BackendOfflineAlert />
    } else {

      return (
        <div
          className="flex flex-col w-full gap-y-8 mb-8">
          <HeroSection
            headingText="Explore the Solana Network's Sustainability"
            subheadingText="Carbon neutral. Climate focused. How the Solana Foundation and greater community are building a better, more energy-efficient web3."
          />
          <Transition
            key={`main-dashboard-${selectedNetwork}`}
            appear={true}
            show={true}
            enter={`${styleConstants.transitionStandard}`}
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave={`${styleConstants.transitionStandard}`}
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="flex flex-col gap-y-20 items-center justify-center w-full md:px-16 mt-4">
              <KpiSection
                ticker={selectedNetwork!}
                kpiData={kpiData[selectedNetwork!]}
              />
              <OffsettingSection />
              <ComparisonSection
                kpiData={kpiData[selectedNetwork!]}
                network={selectedNetwork}
              />
              {
                isMapDataAvailable(selectedNetwork, mapData) &&
                <MapSection
                  networkMapDataEntry={mapData[selectedNetwork!]}
                />}
              <HistoricSection
                historicDataNetwork={historicData[selectedNetwork!]}
              />
              <PoweredByCcri
                positioningClasses='mt-8'
                isLargeVariant
              />
            </div>
          </Transition>
        </div>
      )
    }
  }


  return (
    <PageTransitionWrapper
      component={renderComponent()}
    />
  )
}